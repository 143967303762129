import React, { useEffect, useRef } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

import { AdminNavbar } from "../components/Navbars/AdminNavbar";
import { Footer as AdminFooter } from "../components/Footers/AdminFooter";
import { Sidebar } from "../components/Sidebar/Sidebar.js";

// reactstrap components
import { Container } from "reactstrap";

import routes from "../routes";

export const Admin = (props) => {
  const location = useLocation();

  const mainContent = useRef();

  const cleanTokens = () => {
    localStorage.removeItem("id_token");
    localStorage.removeItem("access_token");
  };

  useEffect(() => {
    document.title = "SolGym - Customer Portal";
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (
      !localStorage.getItem("access_token") ||
      localStorage.getItem("access_token") === "undefined" ||
      localStorage.getItem("access_token") === "null"
    ) {
      console.log("no hay token");
      cleanTokens();
      return <Redirect from="/" to="/auth/login" />;
    }
  }, []);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  if (
    !localStorage.getItem("access_token") ||
    localStorage.getItem("access_token") === "undefined" ||
    localStorage.getItem("access_token") === "null"
  ) {
    cleanTokens();
    return <Redirect from="/" to="/auth/login" />;
  }

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/solgym-nuevo-logo-icon.png"),
          imgAlt: "SolGym Logo",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar brandText={getBrandText(location.pathname)} />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/index" />
        </Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};
