import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import useCustomerData from "../components/CustomHooks/useCustomerData";
import { Loading } from "../components/Loaders/Loading";
import { Header } from "../components/Headers/Header";
import { loadStripe } from "@stripe/stripe-js";
// import { PaymentMethodRow } from "../components/PaymentsMethods/PaymentMethodRow"

import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import axios from "axios";

// reactstrap components
import {
  Modal,
  Card,
  Button,
  CardHeader,
  Table,
  Container,
  Row,
  Form,
  Badge
} from "reactstrap";

const deletePaymentMethodAxios = async (pmID) => {

  try {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_BACKEND_HOST}/${process.env.REACT_APP_BACKEND_API_END}/payment_method`,
      headers: {},
      data: {id : pmID}
    };
    return await axios(config);

  } catch (error) {
    console.log(error);
  }


};

export const PaymetMethods = (props) => {
  const { response, isLoading } = useCustomerData();
  const [newCard, setNewCard] = useState(false);
  // const [card, setCard] = useState({});
  const [cancelModal, setCancelModal] = useState(false);
  const [paymentMethodID, setPaymentMethodID] = useState("");
  const [listPaymentMethods, setListPaymentMethods] = useState([]);

  useEffect(() => {
    setListPaymentMethods(response.paymentsMethods)
  }, [response.paymentsMethods]);

  
  useEffect(() => {
    setListPaymentMethods(listPaymentMethods)
  }, [listPaymentMethods]); 

  const toggleNewCard = (e) => {
    e.preventDefault();
    setNewCard(!newCard);
  };

  const toggleModal = () => {
    setCancelModal(!cancelModal);
  };

  console.log(process.env.REACT_APP_ENV)
  
    
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_LIVE);
  

  // Attach Card to Customer
  const AddCardForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
      event.preventDefault();

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (!error) {
        const { id } = paymentMethod;
        const customerId = response.data.id;

        try {
          const rescard = await axios.post(
            `${process.env.REACT_APP_BACKEND_HOST}/${process.env.REACT_APP_BACKEND_API_END}/payment_method`,
            { id, customerId }
          );
          setNewCard(!newCard);
          const card = (rescard.data)
          console.log(rescard.data)
          console.log(card)
          setListPaymentMethods([...listPaymentMethods, {
            billing_details: card.billing_details,
            card: card.card,
            created: card.created,
            customer: card.customer,
            id: card.id,
            metadata: card.metadata,
            object: card.object,
            type: card.type,
          
          }]);

        } catch (error) {
          console.log(error);
        }
      }
    };
    // Attach Card to Customer End

    return (
    
        <Row className="justify-content-start">
          <div className="col-12 col-sm-12 col-md-12 col-lg-4">
            <Form onSubmit={handleSubmit}>
              <CardElement
                className="CardElement CardElementShadow "
                options={{
                  style: {
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                }}
              />
              <div className="mt-4">
                <Button
                  color="default"
                  size="sm"
                  type="submit"
                  disabled={!stripe}
                >
                  Agregar
                </Button>
                <Button
                  color="secondary"
                  size="sm"
                  type="button"
                  onClick={(e) => {
                    toggleNewCard(e);
                  }}
                >
                  Cancelar
                </Button>
              </div>
            </Form>
          </div>
        </Row>
 
    );
  };

const setPaymentAsDefault = async (pmID) => {
  

  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_HOST}/${process.env.REACT_APP_BACKEND_API_END}/payment_method/setasdefault`,
      headers: {},
      data: {id : pmID, customerId: response.data.id}
    };
    await axios(config);
    setListPaymentMethods([...listPaymentMethods]);
  } catch (error) {
    console.log(error);
  }



}

  const deleteCardCustomer = (e) => {
    e.preventDefault();
    deletePaymentMethodAxios(paymentMethodID);
    setCancelModal(!cancelModal);
    console.log(paymentMethodID)
    setListPaymentMethods(listPaymentMethods.filter((list) => (list.id !== paymentMethodID)));
  };

  if (isLoading) {
    return <Loading />;
  }
  
  if(!response.invoices){
    localStorage.removeItem("id_token");
    localStorage.removeItem("access_token");
    return <Redirect from="/" to="/auth/login" />;
  }

  return (
    <>
      <Header customer={response} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                  <h3 className="mb-0">Metodos de Pago</h3>
              </CardHeader>
              <Table
                className="align-items-center table-flush table-hover"
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Tarjeta</th>
                    <th scope="col">Número</th>
                    <th scope="col">Expira</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {/* <PaymentMethodRow paymentsMethods={response.paymentsMethods} /> */}
                  {
                    // Menssage in case the customer doenst have a source for payments
                    listPaymentMethods.length === 0 ? (
                      <tr>
                        <th scope="row">
                          No tienes un metodo de pago asociado
                        </th>
                        <td></td>
                        <td></td>
                      </tr>
                    ) : (
                      listPaymentMethods.map((payment_method, i) => {
                        return (
                          <tr key={i}>
                            <th scope="row">
                              {/* <img className="avatar rounded-circle mr-3"
                              alt="..."
                              src={require("assets/img/theme/bootstrap.jpg")}
                            /> */}
                              {payment_method.card.brand} 
                              {
                              response.data.invoice_settings.default_payment_method === payment_method.id && 
                              <Badge className="badge-default ml-3">Default</Badge>
                              
                              }
                            </th>
                            <td>...{payment_method.card.last4}</td>
                            <td>
                              {payment_method.card.exp_month} /{" "}
                              {payment_method.card.exp_year}
        
                            </td>
                            <td>
                              {/* <Button
                                color="danger"
                                size="sm"
                                type="button"
                                onClick={() => {
                                  setPaymentMethodID(payment_method.id);
                                  toggleModal();
                                }}
                              >
                                Borrar
                              </Button> */}
                              {
                                response.data.invoice_settings.default_payment_method === payment_method.id ?
                                <Button 
                                color="secondary" 
                                size="sm" 
                                type="button"
                                disabled
                               
                                >
                                  Marcar como Default
                                </Button>
                                :
                                <Button 
                                color="default" 
                                size="sm" 
                                type="button"
                                onClick={() => {
                                  setPaymentAsDefault(payment_method.id);
                                }}
                                
                                >
                                  Marcar como Default
                                </Button>
                              }

                            </td>
                          </tr>
                        );
                      })
                    )
                  }
                </tbody>
              </Table>
            </Card>
            <div className="mt-5">
              {!newCard && (
                <Button
                  color="default"
                  onClick={(e) => {
                    toggleNewCard(e);
                  }}
                >
                  Agregar nueva tarjeta
                </Button>
              )}

              {newCard && (
                <Elements stripe={stripePromise}>
                  <AddCardForm />
                </Elements>
              )}
            </div>
          </div>
        </Row>
      </Container>

           {/* Modal */}
           <Modal
        className="modal-dialog-centered"
        isOpen={cancelModal}
        toggle={() => toggleModal()}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Borrar metodo de Pago
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {/* Nota: su subscription sera cancelada al terminarar el periodo */}
          <h3>
            ¿Estas seguro que quieres borrar este metodo de pago? {}
          </h3>
          <br />
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal()}
          >
            Cancelar
          </Button>
          <Button
            color="danger"
            type="button"
            onClick={(e) => deleteCardCustomer(e)}
          >
            Borrar
          </Button>
        </div>
      </Modal>
    </>
  );
};
