import React from 'react'

export const LoaderAuth = () => {
    return (
        <>
          <div
            className="mb-5 mb-xl-0 d-flex justify-content-center align-items-center"
            xl="12"
          >
            <div className="text-center text-blue lds-ripple-auth">
              <div></div>
              <div></div>
            </div>
          </div>

      </>
    )
}