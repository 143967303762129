import React, { useState, useEffect } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import qs from "qs";

export const Callback = (props) => {
  const location = useLocation();
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  const [token, setToken] = useState({ access_token: "", id_token: "" });

  const conectionParams = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    client_secret: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
    responseType: "code",
    redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    grant_type: "authorization_code",
  };

  const getToken = async (codigo) => {
    try {
      const data = qs.stringify({
        client_id: conectionParams.clientID,
        grant_type: "authorization_code",
        code: codigo,
        redirect_uri: conectionParams.redirectUri,
      });
      const config = {
        method: "post",
        url: `https://${conectionParams.domain}/oauth/token`,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
        data: data,
      };

      const respuesta = await axios(config);

      return respuesta.data;
    } catch (err) {
      return err;
    }
  };

  const saveTokens = (id_token, access_token) => {
    localStorage.setItem("id_token", id_token);
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("last_connection", Date.now());
  };

  const cleanTokens = () => {
    localStorage.removeItem("id_token");
    localStorage.removeItem("access_token");
  };

  let history = useHistory();
  useEffect(() => {
    // Get code from Local URL callback
    const params = new URLSearchParams(location.search);
    setCode(params.get("code"));

    // Get Auth token
    // getToken(params.get("code"))
    getToken(params.get("code")).then(async (response) => {
      if (response.name === "Error") {
          setError(true);
        history.push({
          state: {
            error: {
              code: "Error_Conection",
              message:"Falla de conexión, intente de nuevo"
            }
          },
        });
        if (localStorage.getItem("access_token")) {
          cleanTokens();
        }
      } else {
        saveTokens(response.id_token, response.access_token);
        setToken({
          access_token: response.access_token,
          id_token: response.id_token,
        });
      }
    });
  }, []);

  if (error) {
    // return <h1>Error de conexión</h1>
    return (
      <Redirect
        from="/"
        to="/auth/"

      />
    );
  }

  if (!token.access_token) {
    return (
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    );
  }

  return <Redirect from="/" to="/admin/" />;
};
