import React from 'react'

export const Loading = () => {
    return (
        <>
        <div className="mt-5 bg-gradient-info full-view">
          <div
            className="mb-5 mb-xl-0 d-flex justify-content-center align-items-center"
            xl="12"
          >
            <div className="text-center lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </>
    )
}
