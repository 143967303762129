 import { MainView } from "./views/MainView";
import { Profile } from "./views/Profile";
import { Subcription } from "./views/Subscription";
import { PaymetMethods } from "./views/Payments-Methods";
import { Login } from "./views/Login";

const routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: MainView,
    layout: "/admin"
  },
  {
    path: "/user-profile",
    name: "Tu Perfil",
    icon: "ni ni-user-run text-yellow",
    component: Profile,
    layout: "/admin"
  },
  {
    path: "/subscription",
    name: "Subscripción",
    icon: "ni ni-app text-blue",
    component: Subcription,
    layout: "/admin"
  },
  {
    path: "/paymentmethods",
    name: "Metodos de Pago",
    icon: "ni ni-credit-card text-green",
    component: PaymetMethods,
    layout: "/admin"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  }
];
export default routes;
