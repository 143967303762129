import React, { useState, useEffect } from "react";
import axios from "axios";
// reactstrap components
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Fecha } from "../customs/Fecha";

export const Header = (props) => {
  const [estado, setEstado] = useState("");

  const [cancelAt, setCancelAt] = useState();
  const [badgeType, setbadgeType] = useState({ type: "", text: "" });

  const [modal, setModal] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const toggle = () => setModal(!modal);
  const toggleConfirm = () => setModalConfirm(!modalConfirm);

  const [rSelected, setRSelected] = useState(2);
  const [billingDays, setBillingDays] = useState([2, 6, 16]);
  const [nextbill, setNextbill] = useState({ balance: 0, newcharge: 0 });
  const [toggleMessage, setToggleMessage] = useState(false);

  let billingCycle = "";

  // 2. Get Billing Cycle day / Month / year
  const date_obj2 = new Date(billingCycle * 1000);
  let billingCycleDate = +("0" + date_obj2.getDate()).slice(-2);
  let billingCycleMonth = +("0" + (date_obj2.getMonth() + 1)).slice(-2);
  let billingCycleYear = date_obj2.getFullYear();

  const showConfirmation = async () => {
    setModalConfirm(!modalConfirm);
  };

  // const updateCustomerBillingDate = async (subID, billingCycle, newDate) => {
  //   const data = { subID, billingCycle, newDate };
  //   const config = {
  //     method: "post",
  //     url: `${process.env.REACT_APP_BACKEND_HOST}/${process.env.REACT_APP_BACKEND_API_END}/update_cus_bill`,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };

  //   let respuesta = await axios(config);

  //   // Check for error message and good response
  //   if (!respuesta.data.raw) {
  //     console.log("verdadero")
  //     let cusBalance = Math.abs(respuesta.data.customer.balance/100)
  //     setNextbill({balance: cusBalance, newcharge: 19.90-cusBalance} )

  //     setModal(!modal);
  //     setToggleMessage(false)
  //   }else {
  //     console.log("falso")
  //     alert("Error Code: "+ respuesta.data.statusCode + "\n Error Message"+respuesta.data.raw.message)
  //   }

  // };

  useEffect(() => {
    if (props.customer.subscriptions.length == 0) {
      setEstado("canceled");
      setbadgeType({ type: "danger", text: "Cancelada" });
    } else {
      setEstado(props.customer.data.subscriptions.data[0].status);
      setCancelAt(props.customer.data.subscriptions.data[0].cancel_at);

      estado === "active" &&
        !cancelAt &&
        setbadgeType({ type: "success", text: "Activa" });
      estado === "past_due" &&
        setbadgeType({ type: "warning", text: "Vencida" });
      estado === "unpaid" && setbadgeType({ type: "warning", text: "Impaga" });

      estado === "canceled" &&
        setbadgeType({ type: "danger", text: "Cancelada" });
      estado === "active" &&
        cancelAt &&
        setbadgeType({ type: "danger", text: "Cancelada" });

      estado === "imcomplete" &&
        setbadgeType({ type: "danger", text: "Incompleta" });
      estado === "trialing" && setbadgeType({ type: "info", text: "Trial" });
    }
    // if(!props.customer.data.subscriptions.data[0].metadata.newbilling_updated){
    //   setToggleMessage(true)
    // }
  }, [estado]);

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>
              {toggleMessage === false ? (
                <>
                  <Col lg="6" xl="6">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Gym Member
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {props.customer.data.name}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                              <i className="fas fa-chart-bar" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="mr-2">
                            <i className="fa email-83" /> Email:
                          </span>{" "}
                          <span className="text-nowrap">
                            {props.customer.data.email}
                          </span>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="6">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Plan Actual
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {props.customer.data.subscriptions.data.length ===
                              0
                                ? "Subscripcíon Cancelada"
                                : props.customer.data.subscriptions.data[0].plan
                                    .nickname}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                              <i className="fas fa-chart-pie" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="mr-2">
                            <strong>Status:</strong>
                          </span>{" "}
                          <span className="text-nowrap">
                            {props.customer.data.subscriptions.data.length ===
                            0 ? (
                              "Subscripcíon Cancelada"
                            ) : (
                              <Badge color={badgeType.type} pill>
                                {badgeType.text}
                              </Badge>
                            )}
                          </span>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </>
              ) : (
                <>
                  <Col lg="4" xl="4">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Gym Member
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {props.customer.data.name}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                              <i className="fas fa-chart-bar" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="mr-2">
                            <i className="fa email-83" /> Email:
                          </span>{" "}
                          <span className="text-nowrap">
                            {props.customer.data.email}
                          </span>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4" xl="4">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Plan Actual
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {props.customer.data.subscriptions.data.length ===
                              0
                                ? "Subscripcíon Cancelada"
                                : props.customer.data.subscriptions.data[0].plan
                                    .nickname}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                              <i className="fas fa-chart-pie" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="mr-2">
                            <strong>Status:</strong>
                          </span>{" "}
                          <span className="text-nowrap">
                            {props.customer.data.subscriptions.data.length ===
                            0 ? (
                              "Subscripcíon Cancelada"
                            ) : (
                              <Badge color={badgeType.type} pill>
                                {badgeType.text}
                              </Badge>
                            )}
                          </span>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4" xl="4">
                    {/* <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase mb-0 text-danger text-center"
                        >
                          !Importante!
                        </CardTitle>
                      </div>
                      <div className="text-center">
                        <h4>Escoje tu nueva Fecha de cobro</h4>
                      </div>
                    </Row>
                    <Row>
                      <ButtonGroup className="mt-3 d-flex justify-content-center">
                        {
                        
                          billingDays.map((newbillday, i) => {

                          return (
                            <>
                            {
                               billingCycleDate !== newbillday && (
                                <Button key={i}
                                className="pt-0 pb-0 pl-3 pr-3 mr-2"
                                color="success"
                                onClick={() => setRSelected(newbillday)}
                                active={rSelected === newbillday}
                              >
                                {newbillday}.º
                              </Button>
                               )
                            }
                              
                            </>
                          )
                        })
                        }
                      </ButtonGroup>
                      <ButtonGroup className="mt-3 d-flex justify-content-center">
                        <Button
                          className="pt-0 pb-0 pl-3 pr-3 ml-2"
                          color="primary"
                          onClick={() => (
                            showConfirmation()

                          )
                          }
                        >
                          Cambiar
                        </Button>
                      </ButtonGroup>
                    </Row>
                  </CardBody>
                </Card> */}
                  </Col>
                </>
              )}
            </Row>
          </div>
        </Container>
      </div>

      {/* Modal PAYMENT */}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Fecha Actualizada</ModalHeader>
        <ModalBody>
          <h2>Tu nueva Fecha de Pago ha sido Actualizada</h2>
          <br />
          <strong>Tu nuevo Dia de Cobro automático</strong>
          <p>{rSelected} de cada mes</p>
          <strong>¿Cuanto debes que pagar en tu próxima facturación? </strong>
          <p>{nextbill.newcharge.toFixed(2)}€</p>
        </ModalBody>
        <ModalFooter>
          {/* <Button color="primary" onClick={toggle}>Do Something</Button>{' '} */}
          <Button color="primary" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

      {/* Modal CONFIRM */}
      {/* <Modal isOpen={modalConfirm} toggle={toggleConfirm}>
        <ModalHeader toggle={toggleConfirm}>Confirmación de Actualización</ModalHeader>
        <ModalBody>
                <h2>¿Estas seguro de que quieres cambiar tu nueva fecha de pago?</h2><br/>
                <p>Al día {rSelected} de cada mes</p>

        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={ () => {
                  toggleConfirm()
                  updateCustomerBillingDate(
                    props.customer.data.subscriptions.data[0].id,
                    props.customer.data.subscriptions.data[0].current_period_end,
                    rSelected
                  )
                  }}>Si Confirmo</Button>
          <Button color="primary" onClick={toggleConfirm}>No</Button>
        </ModalFooter>
      </Modal> */}
    </>
  );
};
