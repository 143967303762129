import React, { useState, useEffect } from 'react'

const fDate = (d) => {
  
  return new Date(d * 1000).toLocaleString("en-US", {
   day: "numeric",
   month: "long",
   year: "numeric",
 });
 
};

export const Fecha = (props) => {

 const [fecha, setFecha] = useState('')

 useEffect(() => {
   const formatedDate = fDate(props.date)
  setFecha(formatedDate)
 }, [])
  

 return (
   <>
    <div>
      {fecha}  
    </div>
    </>
  )
}

