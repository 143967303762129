import { useState, useEffect } from "react";
// import { CustomerIDContext } from "../../index";
import axios from "axios";

const useCustomerData = () => {
  // const customerID = useContext(CustomerIDContext);
  const [isLoading, setIsLoading] = useState(true);
  const [customerData, setCustomerData] = useState({});
  const [error, setError] = useState("");
  const [userData, setUserData] = useState("");

  const fetchCustomerData = async () => {
    try {
      // get user from localstorage and set the userData
      const user = JSON.parse(localStorage.getItem("user"));
      setUserData(user);
      const customerID = user.stripeid;
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}/${process.env.REACT_APP_BACKEND_API_END}/fetchdata/` +
          customerID
      );
      setCustomerData(response.data);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCustomerData();
  }, []);

  return {
    response: customerData,
    error,
    isLoading,
  };
};

export default useCustomerData;
