import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { LoaderAuth } from "../components/Loaders/LoaderAuth";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

const isEmptyObject = (obj) => {
  return !Object.keys(obj).length;
};

export const Login = (props) => {
  const [user, setUser] = useState({ email: "", password: "" });
  const [userRecover, setUserRecover] = useState({ name: "", email: "" });
  const [error, setError] = useState({ code: "", message: "" });
  const [errorRecover, setErrorRecover] = useState("");
  const [forgotPwd, setForgotPwd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let history = useHistory();
  const handleError = (code, message) => {
    setError({ code, message });
  };
  const login = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_AUTH_SRV}`, user);
      const token = res.data.token;
      localStorage.setItem("access_token", token);
      const decoded = jwtDecode(token);
      // Store user info like stripeid in local storage or context
      localStorage.setItem("user", JSON.stringify(decoded));
      setIsLoading(false);
      history.push("/admin");
    } catch (err) {
      handleError("access_denied", "Usuario o Contraseña incorrectos");
      setIsLoading(false);
    }
  };

  const recover_login = async (e) => {
    e.preventDefault();

    try {
      if (!userRecover.email) {
        setErrorRecover("empty");
        return;
      }
      // Recover password logic...
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_HOST}/${process.env.REACT_APP_BACKEND_API_END}/recoverpwd`,
        headers: {},
        data: { name: userRecover.name, email: userRecover.email },
      };
      const response = await axios(config);

      isEmptyObject(response.data)
        ? setErrorRecover("error")
        : setErrorRecover("pass");
    } catch (err) {
      handleError("access_denied", "Usuario o Contraseña incorrectos");
      console.log("error: " + err);
    }
  };

  // useEffect(() => {
  //   cleanTokens()
  //   history.replace({ state: undefined });
  //   return <Redirect from="/" to="/auth/login" />;
  // }, [history]);

  return (
    <div>
      <Col lg="12" md="12">
        {
          // Normal Login
          !forgotPwd ? (
            <>
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-1">
                  <div className="text-muted text-center mt-2 mb-3">
                    <div>
                      <div className="pt-1">
                        <img
                          className="img-fluid"
                          alt="SolGym Logo"
                          src={require("../assets/img/brand/logo-transparente.png")}
                          width="256"
                        />
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>Escribe tus credenciales abajo</small>
                  </div>
                  {error.code === "access_denied" ? (
                    <Alert color="warning" fade={true}>
                      <strong>¡Error!</strong> Usuario o Contraseña incorrectos
                    </Alert>
                  ) : error.code === "invalid_request" ? (
                    <Alert color="warning" fade={true}>
                      <strong>¡Error!</strong> Rellene todos los campos
                    </Alert>
                  ) : error === "Error_Conection" ? (
                    <Alert color="warning" fade={true}>
                      <strong>¡Error!</strong> Falla de conexión, intente de
                      nuevo
                    </Alert>
                  ) : (
                    ""
                  )}

                  <Form role="form">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-circle-08" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="tu@email.com"
                          type="text"
                          autoComplete="new-email"
                          onChange={(e) => {
                            setUser({ ...user, email: e.target.value });
                            if (error !== "") {
                              setError("");
                            }
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          type="password"
                          autoComplete="new-password"
                          onChange={(e) => {
                            setUser({ ...user, password: e.target.value });
                            if (error !== "") {
                              setError("");
                            }
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id=" customCheckLogin"
                        type="checkbox"
                      />
                    </div>
                    <div className="text-center">
                      {isLoading === false ? (
                        <Button
                          className="my-2"
                          color="primary"
                          type="button"
                          onClick={login}
                        >
                          Acceder
                        </Button>
                      ) : (
                        <LoaderAuth />
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
              {/*
             <Row className="mt-3">
                <Col xs="6">
                  <a
                    className="text-light"
                    href="#recover"
                    onClick={(e) => setForgotPwd(!forgotPwd)}
                  >
                    <small>Olvide mi contraseña?</small>
                  </a>
                </Col>
              </Row>
              */}
            </>
          ) : (
            // If Forgot Password
            <>
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-1">
                  <div className="text-muted text-center mt-2 mb-3">
                    <div>
                      <div className="pt-1">
                        <img
                          className="img-fluid"
                          alt="SolGym Logo"
                          src={require("../assets/img/brand/logo-transparente.png")}
                          width="256"
                        />
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>
                      <strong>Recupera tu usuario y contraseña</strong>
                    </small>
                  </div>
                  {errorRecover === "error" ? (
                    <Alert color="warning" fade={true}>
                      <strong>¡Error!</strong> Email no existe
                    </Alert>
                  ) : (
                    errorRecover === "empty" && (
                      <Alert color="warning" fade={true}>
                        <strong>¡Error!</strong> Escribe tu email
                      </Alert>
                    )
                  )}
                  {errorRecover === "pass" && (
                    <>
                      <Alert
                        color="success"
                        fade={true}
                        className="text-center"
                      >
                        <strong>Revisa tu email</strong>
                      </Alert>
                      <h5 className="text-center mb-3">
                        te enviamos tus credenciales
                      </h5>
                    </>
                  )}

                  <Form role="form">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-circle-08" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Nombre y Apellido"
                          type="text"
                          autoComplete="new-name"
                          onChange={(e) => {
                            setErrorRecover("");
                            setUserRecover({
                              ...userRecover,
                              name: e.target.value,
                            });
                            if (error !== "") {
                              setError("");
                            }
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-circle-08" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email"
                          autoComplete="new-email"
                          onChange={(e) => {
                            setErrorRecover("");
                            setUserRecover({
                              ...userRecover,
                              email: e.target.value,
                            });
                            if (error !== "") {
                              setError("");
                            }
                          }}
                        />
                      </InputGroup>
                    </FormGroup>

                    {/* <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Ultimos 4 digitos"
                        type="number"
                        min="4"
                        max="4"
                        autoComplete="new-password"
                        onChange={(e) => {
                          setUser({ ...userRecover, last4: e.target.value });
                          if (error !== "") {
                            setError("");
                          }
                        }}
                      />
                    </InputGroup>
                  </FormGroup> */}
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id=" customCheckLogin"
                        type="checkbox"
                      />
                    </div>
                    <div className="text-center">
                      <Button
                        className="my-2"
                        color="primary"
                        type="button"
                        onClick={(e) => recover_login(e)}
                      >
                        Recuperar
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <a
                    className="text-light"
                    href="#login"
                    onClick={(e) => {
                      setForgotPwd(!forgotPwd);
                      setErrorRecover("");
                    }}
                  >
                    <small>Retroceder</small>
                  </a>
                </Col>
              </Row>
            </>
          )
        }
      </Col>
    </div>
  );
};
