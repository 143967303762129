import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import useCustomerData from "../components/CustomHooks/useCustomerData";
import { Loading } from "../components/Loaders/Loading";
import { Header } from "../components/Headers/Header";
import { Fecha } from "../components/customs/Fecha";
import axios from "axios";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Modal,
  Input,
} from "reactstrap";

const cancelSubAxios = async (subscriptionid, periodstart, customerid, schedulesubidx) => {
  const data = {
    subscriptionid,
    periodstart,
    customerid,
    schedulesubidx
  };
  console.log("in Axios")
  console.log(subscriptionid + " " + periodstart + " " +  customerid)
  const config = {
    method: "delete",
    url: `${process.env.REACT_APP_BACKEND_HOST}/${process.env.REACT_APP_BACKEND_API_END}/subscriptions/`,
    headers: {"Access-Control-Allow-Origin": "*"},
    data: data,
  };
  console.log("in Axios 2 " + config)
  const respuesta = await axios(config);
  console.log(respuesta)
  return respuesta
};

const noCancelSubAxios = async (subscriptionid, periodstart, customerid) => {
  const data = {
    subscriptionid,
    periodstart,
    customerid,
  };

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_HOST}/${process.env.REACT_APP_BACKEND_API_END}/subscriptions/nocancel`,
    headers: {},
    data: data,
  };
  return await axios(config);
};

export const Subcription = (props) => {
  const { response, isLoading } = useCustomerData();
  const [cancelModal, setCancelModal] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState("");
  const [cancelMessage, setCancelMessage] = useState("");
  const [subID, setSubID] = useState("");
  const [status, setStatus] = useState("");
  const [scheduleSub, setscheduleSub] = useState("");
  const [canceledData, setCanceledData] = useState({});

  const toggleModal = (subid, schedulesubid) => {
    setSubID(subid);
    setCancelModal(!cancelModal);
    setscheduleSub(schedulesubid);
  };

  const cancelSubscription = async (e) => {
    e.preventDefault();
    let currentPeriodStart = response.subscriptions[0].current_period_start;
    scheduleSub ? console.log(scheduleSub) : console.log("Not Schedule")

    let customerID = response.data.id;
    if (confirmCancel === "CANCELAR") {

      console.log("In cancel")
      const resCancel = await cancelSubAxios(
        subID,
        currentPeriodStart,
        customerID,
        scheduleSub
      );
      console.log(resCancel)
      setCanceledData({
        cancel_at: resCancel.data.cancel_at,
        canceled_at: resCancel.data.canceled_at
      })
            
      setCancelMessage("SUCCESS");
      setStatus("canceled");
      

      setTimeout(() => {
        toggleModal();
      }, 1000);
    } else {
      setCancelMessage("ERROR");
    }
  };

  const noCancelSubscription = async (e, subID) => {
    e.preventDefault();
    await noCancelSubAxios(subID);
    setStatus("");
    setCancelMessage("");
  }

  if (isLoading) {
    return <Loading />;
  }

  if(!response.invoices){
    localStorage.removeItem("id_token");
    localStorage.removeItem("access_token");
    return <Redirect from="/" to="/auth/login" />;
  }

  return (
    <>
      <Header customer={response} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Subscripción actual</h3>
              </CardHeader>
              <Table
                className="align-items-center table-flush table-hover"
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Subscripción</th>
                    <th scope="col">Monto</th>
                    <th scope="col">Status</th>
                    {/* <th scope="col">Acción</th> */}
                  </tr>
                </thead>
                <tbody>
                  {response.subscriptions.map((subscription, i) => {
                    return (
                      <tr key={i}>
                        <th scope="row">{subscription.plan.nickname}
                          {/* Cancel button */}
                                                {
                          status === "canceled" || subscription.cancel_at? (
                            <>
                              <Button
                              color="default"
                              href="#"
                              onClick={(e) => noCancelSubscription(e, subscription.id)}
                              size="sm"
                              style={{marginLeft: "10px"}}
                            >
                              No Cancelar 
                            </Button>
                            <Button
                              color="secondary"
                              disabled
                              href="#"
                              onClick={() => toggleModal(subscription.id)}
                              size="sm"
                              style={{marginLeft: "10px"}}
                            >
                              cancelada 
                              {!canceledData.cancel_at ?
                              <Fecha date={subscription.cancel_at}/>:
                              <Fecha date={canceledData.cancel_at}/>
                              }
                            </Button>
 
                            </>
                          ) : (
                            <Button
                              color="danger"
                              href="#"
                              onClick={() => toggleModal(subscription.id, subscription.schedule)}
                              size="sm"
                              style={{marginLeft: "10px"}}
                            >
                              cancelar
                            </Button>
                          )}
                        {/* End cancel button */}
                        
                        </th>
                        <td>{subscription.plan.amount_decimal / 100}€</td>
                        <td>
                          {subscription.status === "active" ? (
                            <Badge color="" className="badge-dot">
                              <i className="bg-success" />
                              Activa
                            </Badge>
                          ) : subscription.status === "canceled" ? (
                            <Badge color="" className="badge-dot">
                              <i className="bg-danger" />
                              Cancelada
                            </Badge>
                          ) : subscription.status === "unpaid" ? (
                            <Badge color="" className="badge-dot">
                              <i className="bg-warning" />
                              Impaga
                            </Badge>
                          ) : subscription.status === "past_due" ? (
                            <Badge color="" className="badge-dot">
                              <i className="bg-warning" />
                              Impaga
                            </Badge>
                          ) : subscription.status === "trialing" ? (
                            <Badge color="" className="badge-dot">
                              <i className="bg-default" />
                              Trial
                            </Badge>
                          ) : subscription.status === "incomplete" ? (
                            <Badge color="" className="badge-dot">
                              <i className="bg-warning" />
                              Incompleta
                            </Badge>
                          ) : (
                            <Badge color="" className="badge-dot">
                              <i className="bg-warning" />
                              Incompleta y Expirada
                            </Badge>
                          )}
                          {/* {
                          (status === "canceled" || subscription.cancel_at) && (
                            <Badge color="info" className="ml-4" pill>
                              <Fecha date={subscription.cancel_at}/>
                            </Badge>
                          )
                          } */}
                        </td>
                        {/* <td>
                          {
                          status === "canceled" || subscription.cancel_at? (
                            <>
                              <Button
                              color="default"
                              href="#"
                              onClick={(e) => noCancelSubscription(e, subscription.id)}
                              size="sm"
                            >
                              No Cancelar 
                            </Button>
                            <Button
                              color="secondary"
                              disabled
                              href="#"
                              onClick={() => toggleModal(subscription.id)}
                              size="sm"
                            >
                              cancelada 
                              {!canceledData.cancel_at ?
                              <Fecha date={subscription.cancel_at}/>:
                              <Fecha date={canceledData.cancel_at}/>
                              }
                            </Button>
 
                            </>
                          ) : (
                            <Button
                              color="danger"
                              href="#"
                              onClick={() => toggleModal(subscription.id, subscription.schedule)}
                              size="sm"
                            >
                              cancelar
                            </Button>
                          )}
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
      {/* Modal */}
      <Modal
        className="modal-dialog-centered"
        isOpen={cancelModal}
        toggle={() => toggleModal()}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Cancelar Subscripción
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {/* Nota: su subscription sera cancelada al terminarar el periodo */}
          <h3>
            Escriba la palabra <strong>CANCELAR</strong>
          </h3>
          <h3>para confirmar que deseas cancelar tu Subscripción</h3>
          <br />
          {cancelMessage === "ERROR" ? (
            <div className="text-danger">
              Escriba <strong>CANCELAR</strong> para confirmar
            </div>
          ) : cancelMessage === "SUCCESS" ? (
            <div className="text-success">Subscripción Cancelada</div>
          ) : (
            ""
          )}
          <Input
            className="mt-3"
            placeholder="CANCELAR"
            type="text"
            onChange={(e) => {
              setCancelMessage("");
              setConfirmCancel(e.target.value);
            }}
          />
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal()}
          >
            Cerrar
          </Button>
          <Button
            color="primary"
            type="button"
            onClick={(e) => cancelSubscription(e) }
          >
            Confirmar
          </Button>
        </div>
      </Modal>
    </>
  );
};
