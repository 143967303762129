import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import useCustomerData from "../components/CustomHooks/useCustomerData";
import { Loading } from "../components/Loaders/Loading";
import { Header } from "../components/Headers/Header";
import { Fecha } from "../components/customs/Fecha";
import axios from "axios";

// reactstrap components
import {
  Modal,
  Badge,
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

const payInvoiceAxios = async (invoiceid) => {
  const data = {
    invoiceid,
  };

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_HOST}/${process.env.REACT_APP_BACKEND_API_END}/pay_invoice/`,
    headers: {},
    data: data,
  };
  return await axios(config);
};

export const MainView = (props) => {
  const { response, isLoading } = useCustomerData();
  const [toggleModal, setToggleModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [listInvoices, setListInvoices] = useState([]);

  useEffect(() => {
    setListInvoices(response.invoices);
  }, [response.invoices]);

  useEffect(() => {
    setListInvoices(listInvoices);
  }, [listInvoices]);

  // PAY INVOICE
  const payInvoice = async (e, invoiceid, i) => {
    e.preventDefault();
    const payRes = await payInvoiceAxios(invoiceid);
    if (payRes.data.rawType === "card_error") {
      alert(
        `Pago Fallido\n
        Codigo: ${payRes.data.code}
        Motivo: ${payRes.data.decline_code}`
      );
    }
    if (payRes.data.rawType === "invalid_request_error") {
      alert(
        `Pago Fallido\n
          Codigo: ${payRes.data.code}
          Motivo: ${payRes.data.raw.message}`
      );
    } else {
      listInvoices[i].amount_paid = 1990;
      listInvoices[i].status = "paid";

      alert(`Tu Factura ha sido pagada\n
      Estatus: ${payRes.data.status}`);
      setListInvoices([...listInvoices]);
    }
  };
  // END PAY INVOICE

  if (!localStorage.getItem("access_token")) {
    return <Redirect from="/" to="/auth/login" />;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!response.invoices) {
    localStorage.removeItem("id_token");
    localStorage.removeItem("access_token");
    return <Redirect from="/" to="/auth/login" />;
  }

  return (
    <>
      <Header customer={response} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="text-white mb-0">{this.state.customer.name}</h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: this.state.activeNav === 1
                          })}
                          href="#pablo"
                          onClick={e => this.toggleNavs(e, 1)}
                        >
                          <span className="d-none d-md-block">Month</span>
                          <span className="d-md-none">M</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: this.state.activeNav === 2
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={e => this.toggleNavs(e, 2)}
                        >
                          <span className="d-none d-md-block">Week</span>
                          <span className="d-md-none">W</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
    
                <div className="chart">
                  <Line
                    data={chartExample1[this.state.chartExample1Data]}
                    options={chartExample1.options}
                    getDatasetAtEvent={e => console.log(e)}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Performance
                    </h6>
                    <h2 className="mb-0">Total orders</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>

                <div className="chart">
                  <Bar
                    data={chartExample2.data}
                    options={chartExample2.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row> */}
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Facturas</h3>
                  </div>
                  <div className="col text-right">
                    <i className="fas fa-file-invoice"></i>
                  </div>
                </Row>
              </CardHeader>
              <Table
                className="align-items-center table-flush table-hover"
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Monto</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Detalles</th>
                    <th scope="col">Número de Factura</th>
                    <th scope="col">Cliente</th>
                    <th scope="col">Fecha</th>
                  </tr>
                </thead>
                <tbody>
                  {response.invoices.length === 0 ? (
                    <tr>
                      <th scope="row">Todavia no tienes facturas</th>
                      <td></td>
                      <td></td>
                    </tr>
                  ) : (
                    listInvoices.map((invoice, i) => {
                      return (
                        <tr key={i}>
                          <th scope="row">{invoice.amount_paid / 100}€</th>
                          <td>
                            {invoice.status === "paid" ? (
                              // <Badge color="success" className="badge-dot">
                              <Badge color="success" pill>
                                <i className="ni ni-check-bold" />
                                Pagada
                              </Badge>
                            ) : invoice.status === "void" ? (
                              <Badge color="primary" pill>
                                <i className="ni ni-bold-right" />
                                Nula
                              </Badge>
                            ) : invoice.status === "open" ? (
                              <Badge color="danger" pill>
                                <i className="ni ni-fat-remove" />
                                Fallida
                              </Badge>
                            ) : invoice.status === "uncollectible" ? (
                              <Badge color="danger" pill>
                                <i className="ni ni-fat-remove" />
                                Incobrable
                              </Badge>
                            ) : (
                              <Badge color="danger" pill>
                                <i className="ni ni-fat-remove" />
                                Fallida
                              </Badge>
                            )}
                          </td>
                          <td>
                            {invoice.status === "open" ? (
                              <Button
                                color="success"
                                href={invoice.id}
                                onClick={(e) => payInvoice(e, invoice.id, i)}
                                target="_blank"
                                size="sm"
                              >
                                Reintentar Págo
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                href={invoice.hosted_invoice_url}
                                target="_blank"
                                size="sm"
                              >
                                Ver Factura
                              </Button>
                            )}
                          </td>
                          <td>{invoice.number}</td>
                          <td>{invoice.customer_email}</td>
                          <td>
                            <Fecha date={invoice.created} />
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
        <Row className="mb-5 mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Ultimos Pagos</h3>
                  </div>
                  <div className="col text-right">
                    <i className="fas fa-credit-card"></i>
                  </div>
                </Row>
              </CardHeader>
              <Table
                className="align-items-center table-flush table-hover"
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Monto</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Descripción</th>
                    <th scope="col">Fecha</th>
                  </tr>
                </thead>
                <tbody>
                  {response.payments.length === 0 ? (
                    <tr>
                      <th scope="row">Todavia no tienes pagos</th>
                      <td></td>
                      <td></td>
                    </tr>
                  ) : (
                    response.payments.map((payment, i) => {
                      return (
                        <tr key={i}>
                          <th scope="row">{payment.amount / 100}€</th>
                          <td>{payment.status}</td>
                          <td>{payment.description}</td>
                          <td>
                            <Fecha date={payment.created} />
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* <Modal
              className="modal-dialog-centered modal-danger"
              contentClassName="bg-gradient-danger"
              isOpen={this.state.notificationModal}
              toggle={() => this.toggleModal("notificationModal")}
            >
              <div className="modal-header">
                <h6 className="modal-title" id="modal-title-notification">
                  Your attention is required
                </h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("notificationModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="py-3 text-center">
                  <i className="ni ni-bell-55 ni-3x" />
                  <h4 className="heading mt-4">You should read this!</h4>
                  <p>
                    A small river named Duden flows by their place and
                    supplies it with the necessary regelialia.
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <Button className="btn-white" color="default" type="button">
                  Ok, Got it
                </Button>
                <Button
                  className="text-white ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("notificationModal")}
                >
                  Close
                </Button>
              </div>
            </Modal> */}
    </>
  );
};
