import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./assets/plugins/nucleo/css/nucleo.css";
import "./assets/icons/css/all.min.css";
import "./assets/css/argon-dashboard-react.css";
import "./assets/css/custom.css";
// import "assets/css/argon-dashboard-react.min.css";
// import "assets/css/argon-dashboard-react.css.map";
//import App from './App';
import { Admin as AdminLayout } from "./layouts/Admin";
import { Auth as AuthLayout } from "./layouts/Auth.js";
import { SignUp } from "./layouts/SignUp.js";
import { Callback } from "./layouts/Callback";
import * as serviceWorker from "./serviceWorker";

// export const CustomerIDContext = React.createContext();;


ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/signup" render={(props) => <SignUp {...props} />} />
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
      <Route path="/callback" render={(props) => <Callback {...props} />} />
      <Redirect from="/" to="/auth/login" />
      {/* <CustomerIDContext.Provider value='cus_GnN8WrAgb6exDm
'>
      </CustomerIDContext.Provider> */}


    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
