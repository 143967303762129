import React, { useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import useCustomerData from "../CustomHooks/useCustomerData";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
  Alert,
} from "reactstrap";

export const AdminNavbar = (props) => {
  const { response, isLoading } = useCustomerData();

  const logOut = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
  };

  if (isLoading) {
    return <div className="bg-gradient-info"></div>;
  }

  return (
    <Navbar
      className="navbar-top navbar-dark bg-dark"
      expand="md"
      id="navbar-main"
    >
      <Container fluid>
        <Link
          className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
          to="/"
        >
          {props.brandText}
        </Link>
        <Nav className="align-items-center d-none d-md-flex" navbar>
          <UncontrolledDropdown nav>
            <DropdownToggle className="pr-0" nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img
                    alt="..."
                    src={require("../../assets/img/profile/avatar.jpg")}
                  />
                </span>
                <Media className="ml-2 d-none d-lg-block">
                  <span className="mb-0 text-sm font-weight-bold">
                    {response.data.name}
                  </span>
                </Media>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Bienvenid@!</h6>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-user-run" />
                <span>Mi perfil</span>
              </DropdownItem>
              <DropdownItem to="/admin/subscription" tag={Link}>
                <i className="ni ni-app" />
                <span>Subscripción</span>
              </DropdownItem>
              <DropdownItem to="/admin/paymentmethods" tag={Link}>
                <i className="ni ni-credit-card" />
                <span>Metodos de Pago</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem to="/auth/login" tag={Link} onClick={logOut}>
                <i className="ni ni-button-power" />
                <span>Desconectar</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Container>
    </Navbar>
  );
};
