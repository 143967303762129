import React from "react";
// reactstrap components
import { NavItem, NavLink, Nav, Row, Col, Container } from "reactstrap";

export const AuthFooter = (props) => {
  return (
    <>
    <footer className="py-5">
      <Container>
        <Row className="align-items-center justify-content-xl-between">
          <Col xl="6">
            <div className="copyright text-center text-xl-left text-muted">
              © 2020{" "}
              <a
                className="font-weight-bold ml-1"
                href="https://www.solgym.es"
                target="_blank"
                rel="noopener noreferrer"
              >
                SolGym
              </a>
            </div>
          </Col>
          <Col xl="6">
            <Nav className="nav-footer justify-content-center justify-content-xl-end">
              <NavItem>
                <NavLink
                  href="https://www.solgym.es"
                  target="_blank"
                >
                  
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </Container>
    </footer>
    </>
  );
};
