import React from 'react'

// reactstrap components
import { Container, Row, Col } from "reactstrap";

export const UserHeader = (props) => {
    // const { response, error, isLoading } = useCustomerData();
    // if (isLoading) {
    //   return <h1>Loading...</h1>
    // }
    return (
        <>
        <div
          className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
          style={{
            minHeight: "600px",
            backgroundImage:
              "url(" + require("../../assets/img/profile/profile-cover.jpg") + ")",
            backgroundSize: "cover",
            backgroundPosition: "center top"
          }}
        >
          {/* Mask */}
          <span className="mask bg-gradient-default opacity-8" />
          {/* Header container */}
          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col lg="7" md="10">
                <h1 className="display-2 text-white">Hola {props.customer.data.name}</h1>
                <p className="text-white mt-0 mb-5">
                  En esta página podras editar tus datos personales, como telefono e email.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    )
}
